import Slider from '@mui/material/Slider';
import {Amount} from '../../../common/Amount/Amount';
import styles from '../Filter/styles.module.sass';


export const DepositFilter = ({
                                title,
                                chart,
                                value,
                                minValue,
                                maxValue,
                                onChange,
                                disabled,
                                usePlus,
                              }) => {

  function processValue(newValueElement) {
    // if (minValue < 0) {
    //   console.log("newValueElement=", newValueElement);
    if (newValueElement < 25) {
      let percent = newValueElement / 25;
      newValueElement = minValue - minValue * (percent)
    } else if (newValueElement < 50) {
      let percent = (newValueElement - 25) / 25;
      newValueElement = (maxValue * 0.55) * percent;
    } else if (newValueElement < 75) {
      let percent = (newValueElement - 50) / 25;
      newValueElement = maxValue * 0.55 + (maxValue * 0.90 - maxValue * 0.55) * percent;
    } else {
      let percent = (newValueElement - 75) / 25;
      newValueElement = maxValue * 0.90 + (maxValue - maxValue * 0.90) * percent;
    }
    return newValueElement
    // }

    // return newValueElement;
  }

  //
  // function processFromValue(newValueElement) {
  //   if (newValueElement < 0) {
  //     let percent = 1 - (newValueElement / -minValue);
  //     newValueElement = 25 * percent
  //   } else if (newValueElement < maxValue * 0.55) {
  //     let percent = newValueElement / (maxValue * 0.55);
  //     newValueElement = 25 * percent + 25;
  //   } else if (newValueElement < maxValue * 0.90) {
  //     let percent = newValueElement / (maxValue * 0.55 + (maxValue * 0.90 - maxValue * 0.55));
  //     newValueElement = 25 * percent + 50;
  //   } else {
  //     let percent = newValueElement / (maxValue * 0.90 + (maxValue - maxValue * 0.90));
  //     newValueElement = 25 * percent + 75;
  //   }
  //   if (isNaN(newValueElement)) return 0;
  //   return newValueElement
  // }



  return (
    <div className={styles.filter}>
      <div className={styles.title}>{title}</div>
      <div className={styles.chart}>{chart}</div>
      <Slider
        disabled={disabled}
        // value={[processFromValue(value[0]), 100]}
        value={value}
        scale={(value) => processValue(value)}
        onChange={(event, newValue) => onChange(newValue)}
        // onChange={(event, newValue) => {
        //   const qq = processValue(newValue[0], minValue, maxValue)
        //   const ww = processValue(newValue[1], minValue, maxValue)
        //   onChange([qq, ww]);
        // }}
        min={0}
        max={100}
        marks
        step={5}
        valueLabelDisplay={'auto'}
        getAriaValueText={(value) => {
          return <Amount value={"!!!"+value}/>;
        }}
        valueLabelFormat={(value) => {
          return <Amount value={value}/>;
        }}
      />
    </div>
  )
};
