import {scalePow} from 'd3-scale';
import lodash from 'lodash';

export const getColorScale = (values) => {
  const minValue = Math.max(0, lodash.min(values));
  const maxValue = lodash.max(values);


  // const negativeScale = scalePow()
  //   .exponent(2.5)
  //   .domain([minValue, 0])
  //   .range(['#B5D1FB', '#E2EDFE']);
  // const positiveScale = scalePow()
  //   .exponent(2.5)
  //   .domain([0, maxValue])
  //   .range(['#B5D1FB', '#7BADF7']);


  const interval = maxValue - minValue;
  const threshold1 = minValue + interval * 0.55
  const threshold2 = minValue + interval * 0.90

  return (totalValue) => {

    if (totalValue > threshold2) return '#7BADF7';
    else if (totalValue > threshold1) return '#B5D1FB';
    else if (totalValue > 0) return '#CBDFFD';
    else return '#E2EDFE'

    // if (totalValue >= 0) {
    //   return positiveScale(totalValue);
    // }
    // return negativeScale(totalValue);
  }
};
