// import mockData from './mockData.json';
import React, {useMemo, useState, useCallback, useEffect} from 'react';
import dayjs from 'dayjs';
import lodash from 'lodash';
import {PERIODS, PERIODS_RU, RATES_ALFA} from '../../../../modules/Periods';
import {getColorScale} from '../../../../modules/ColorScale';
import {RATINGS} from '../../../../modules/Ratings';
import useLocalStorage from '../../../../modules/hooks/useLocalStorage';
import {Header} from '../../common/Header/Header';
import {Params} from './Params/Params';
import {Filters} from './Filters/Filters';
import {HowItWorks} from './HowItWorks/HowItWorks';
import {TitleFilter} from './TitleFilter/TitleFilter';
import LoadingSpinner from './LoadingSpinner';
import {Suggestions} from './Suggestions';
import {OfflineExchangeModal} from './OfflineExchangeModal/OfflineExchangeModal';
import {DEFAULT_TAX_RATE} from './index';
import styles from './styles.module.sass';
import './common.sass';

const TOTAL_AMOUNT_LIMIT = 100000000; // 100млн

export const MEASURED_VALUES_MAP = {
  INCOME: 'income',
  DIFFERENCE_WITH_DEPOSIT: 'differenceWithDeposit',
};

const DEFAULT_MEASURED_VALUE = MEASURED_VALUES_MAP.DIFFERENCE_WITH_DEPOSIT;
const DEFAULT_RATING_VALUE = [4, 8];
const DEFAULT_PERIOD_VALUE = [0, 6];

export const getExtremums = (data, measuredValue) => {
  let min = Infinity;
  let max = -Infinity;
  lodash.forEach(data, (periods) => {
    lodash.forEach(periods, (bonds) => {
      bonds.forEach((bond) => {
        min = Math.min(min, bond[measuredValue]);
        max = Math.max(max, bond[measuredValue]);
      });
    });
  });
  return [min, max];
};

export const getMeasuredValues = (data, measuredValue) => {
  const values = [];
  lodash.forEach(data, (periods) => {
    lodash.forEach(periods, (bonds) => {
      bonds.forEach((bond) => {
        values.push(bond[measuredValue]);
      });
    });
  });
  return values;
};

export const getDepositsByPeriods = (totalAmount, taxRate) => Object.keys(RATES_ALFA).reduce((acc, key) => {
  const [term, rate] = RATES_ALFA[key];
  // taxRate  = 0.13
  // acc[key] = ((rate / 100) / 365) * term * totalAmount * (1 - taxRate);
  acc[key] = ((rate / 100) / 365) * term * totalAmount;

  const totalYears = Math.ceil(term/365);

  const nonTaxableIncome = (1000000 * 7.5 / 100 ) * totalYears;
  const tax = Math.max(0, (acc[key] - nonTaxableIncome) * taxRate);
  acc[key] -= tax;

  return acc;
}, {});

export const prepareData = (bondsData, totalAmount, depositsByPeriods, taxRate) => {
  if (!bondsData || Object.keys(bondsData).length === 0) {
    return {};
  }
  return RATINGS.reduce((accI, rating) => {
    accI[rating] = PERIODS.reduce((accJ, period, j) => {
      accJ[period] = bondsData[rating][period].map((bond) => {
        const income = Math.round(bond.return.amountReceivedAfterTax) - Math.round(bond.return.amountPurshased);
        const differenceWithDeposit = income - Math.round(depositsByPeriods[period]);
        return {
          isin: bond.bond.isin,
          issuerName: bond.bond.issuer,
          issueNumber: bond.bond.issueNumber,
          income,
          differenceWithDeposit,
          // yield: bond.transactions[0].yield * 100 * (1 - taxRate),
          yield: bond.return.annualizedTotalReturnAfterTax * 100,
          pricePercent: differenceWithDeposit / totalAmount * 100,
          totalResult: bond.return.amountReceivedAfterTax,
          sector: bond.bond.sector,
          nationalRating: bond.bond.nationalRating,
          ratingGroup: rating,
          redemptionDate: bond.transactions[1].date,
          depositYield: RATES_ALFA[period][1],// * (1 - taxRate),
          depositTotal: totalAmount + depositsByPeriods[period],
          depositIncome: depositsByPeriods[period],
          buyPrice: bond.transactions[0].price,
          buyQuantity: bond.transactions[0].quantity,
          period,
          periodLabel: PERIODS_RU[j],
          proposalType: bond.transactions[1].type,
        };
      });
      accJ[period] = accJ[period].sort((a, b) => b.income - a.income);
      return accJ;
    }, {});
    return accI;
  }, {})
}

export const filterData = (data, ratingFilter, periodFilter, measuredValueFilter, measuredValue,minValue, maxValue) => {
  if (!data || Object.keys(data).length === 0) {
    return {};
  }

  function processValue(newValueElement) {
    // if (minValue < 0) {
    //   console.log("newValueElement=", newValueElement);
    if (newValueElement < 25) {
      let percent = newValueElement / 25;
      newValueElement = minValue - minValue * (percent)
    } else if (newValueElement < 50) {
      let percent = (newValueElement - 25) / 25;
      newValueElement = (maxValue * 0.55) * percent;
    } else if (newValueElement < 75) {
      let percent = (newValueElement - 50) / 25;
      newValueElement = maxValue * 0.55 + (maxValue * 0.90 - maxValue * 0.55) * percent;
    } else {
      let percent = (newValueElement - 75) / 25;
      newValueElement = maxValue * 0.90 + (maxValue - maxValue * 0.90) * percent;
    }
    return newValueElement
    // }

    // return newValueElement;
  }


  return RATINGS.slice(ratingFilter[0], ratingFilter[1]).reduce((accI, rating) => {
    accI[rating] = PERIODS.slice(periodFilter[0], periodFilter[1]).reduce((accJ, period) => {
      accJ[period] = data[rating][period].filter(
        (item) => item[measuredValue] >= processValue(measuredValueFilter[0]) && item[measuredValue] <= processValue(measuredValueFilter[1])
      );
      return accJ;
    }, {});
    return accI;
  }, {})
}

export const HomeController = ({
  bondsData,
  calendarData,
  pending,
  error,
  totalAmount,
  taxRate,
  onTotalAmountChange,
  onTaxRateChange,
}) => {
  const depositsByPeriods = useMemo(
    () => getDepositsByPeriods(totalAmount, taxRate),
    [totalAmount, taxRate]
  );
  const data = useMemo(
    () => prepareData(bondsData, totalAmount, depositsByPeriods, taxRate),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bondsData, totalAmount, taxRate]
  );

  const [measuredValue, setMeasuredValue] = useState(DEFAULT_MEASURED_VALUE);
  const [ratingFilter, setRatingFilter] = useState(DEFAULT_RATING_VALUE);
  const [periodFilter, setPeriodFilter] = useState(DEFAULT_PERIOD_VALUE);

  const [minMeasuredValue, maxMeasuredValue] = useMemo(() => getExtremums(data, measuredValue), [data, measuredValue]);
  const [measuredValueFilter, setMeasuredValueFilter] = useState([25, 100]); //TODO:  25 - процентов - это 0 разницы с депозитом по новой шкале, а 100 - это максимальная
  const [totalAmountFromInput, setTotalAmountFromInput] = useState(totalAmount);

  const filteredData = useMemo(() => filterData(data, ratingFilter, periodFilter, measuredValueFilter, measuredValue,minMeasuredValue, maxMeasuredValue),
    [ratingFilter, periodFilter, measuredValueFilter, measuredValue, data]
  );
  const measuredValues = useMemo(() => getMeasuredValues(filteredData, measuredValue), [filteredData, measuredValue]);
  const colorScale = getColorScale(measuredValues);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTotalAmountChange = useCallback(lodash.debounce(onTotalAmountChange, 400), []);
  useEffect(() => {
    const newValue = totalAmountFromInput && totalAmountFromInput > TOTAL_AMOUNT_LIMIT ? TOTAL_AMOUNT_LIMIT : totalAmountFromInput;
    setTotalAmountFromInput(newValue);
    handleTotalAmountChange(newValue);
  }, [totalAmountFromInput, handleTotalAmountChange]);
  useEffect(() => {
    setMeasuredValueFilter([25, 100]);      //TODO:  25 - процентов - это 0 разницы с депозитом по новой шкале, а 100 - это максимальная
  }, [minMeasuredValue, maxMeasuredValue]);

  const [controlStateLocalStorage, setControlStateLocalStorage] = useLocalStorage('controlsState'); // info | filters
  const [controlsState, setControlsState] = useState('filters');
  const onChangeControl = (state) => {
    const newState = state === controlsState ? 'empty' : state;
    setControlsState(newState);
    setControlStateLocalStorage(newState);
    onTaxRateChange(DEFAULT_TAX_RATE);
    setMeasuredValue(DEFAULT_MEASURED_VALUE);
    setRatingFilter(DEFAULT_RATING_VALUE);
    setPeriodFilter(DEFAULT_PERIOD_VALUE);
  };

  const isOfflineExchange = useMemo(() => {
    const mscDateTime = dayjs().tz('Europe/Moscow');
    const mscTimeNumber = +mscDateTime.format('HHmmss');
    const isHolidays = calendarData?.includes(mscDateTime.format('YYYYMMDD'));
    /** NB: время работы биржи с 9:50 до 18:50 */
    const isJobTime = mscTimeNumber >= 95000 && mscTimeNumber < 185000;
    return isHolidays || !isJobTime;
  }, [calendarData]);
  const [forceOnline, setForceOnline] = useState(false);

  return (
    <div className={styles.page}>
      <Header
        controlsState={controlsState}
        onChangeControl={onChangeControl}
      />
      {controlsState === 'filters' && (
        <>
          <div className={styles.paramsPanel}>
          <Params
            totalAmountFromInput={totalAmountFromInput}
            setTotalAmountFromInput={setTotalAmountFromInput}
            taxRate={taxRate}
            onTaxRateChange={onTaxRateChange}
            measuredValue={measuredValue}
            setMeasuredValue={setMeasuredValue}
          />
          <Filters
            measuredValue={measuredValue}
            minMeasuredValue={minMeasuredValue}
            maxMeasuredValue={maxMeasuredValue}
            measuredValueFilter={measuredValueFilter}
            setMeasuredValueFilter={setMeasuredValueFilter}
            ratingFilter={ratingFilter}
            setRatingFilter={setRatingFilter}
            periodFilter={periodFilter}
            setPeriodFilter={setPeriodFilter}
          />
          </div>
        </>
      )}
      {controlsState === 'info' && (
        <HowItWorks onClose={() => onChangeControl('empty')} />
      )}
      {controlsState !== 'filters' && (
        <TitleFilter
          totalAmountFromInput={totalAmountFromInput}
          setTotalAmountFromInput={setTotalAmountFromInput}
        />
      )}

      {Boolean(totalAmount) === false && (
        <div className={styles.messageCover}>
          <div className={styles.messageCover_caption}>
            {'Введите значение больше 0'}
          </div>
        </div>
      )}
      {Boolean(totalAmount) !== false && error && (
        <div className={styles.messageCover}>
          <div className={styles.messageCover_error}>
            {'Ошибка загрузки данных'}
          </div>
        </div>
      )}
      {pending && (
        <div className={styles.messageCover}>
          <div className={styles.messageCover_caption}>
            {'Загрузка'}
          </div>
          <LoadingSpinner />
        </div>
      )}
      {!pending && !error && (<Suggestions
        data={filteredData}
        colorScale={colorScale}
        measuredValue={measuredValue}
        isFilterView={controlsState === 'filters'}
      />)}
      <OfflineExchangeModal
        isOpen={isOfflineExchange && !forceOnline}
        onClose={() => setForceOnline(true)}
      />
    </div>
  );
};
