import React from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import {Button} from './Button';
import scbInvestLogo from './scb-invest-logo.svg';
import styles from './styles.module.sass';

export const Header = ({controlsState, onChangeControl}) => (
  <div className={styles.header}>
    <div className={styles.logoContainer}>
      <div className={styles.logo}>
        <img src={scbInvestLogo} alt="Совкомбанк Инвестиции" />
      </div>
      <div className={styles.logoHr}></div>
      <div className={styles.logoInfo}>
        подбор облигаций
      </div>
    </div>
    <div className={styles.controls}>
      {/*<Button*/}
      {/*  isActive={controlsState === 'info'}*/}
      {/*  onClick={() => onChangeControl('info')}*/}
      {/*>*/}
      {/*  Как это работает*/}
      {/*</Button>*/}
      {/*<Button*/}
      {/*  isActive={controlsState === 'filters'}*/}
      {/*  onClick={() => onChangeControl('filters')}*/}
      {/*>*/}
      {/*  <TuneIcon />*/}
      {/*</Button>*/}
    </div>
  </div>
);
