import classNames from 'classnames';
import styles from './BondSuggestion.module.sass';

const EmptyBondSuggestion = () => {
  return (
    <div
      className={classNames(
        styles.suggestion,
        styles.__empty,
        styles.__skeleton
      )}
      onClick={() => null}
    >


    </div>
  );
};

export default EmptyBondSuggestion;
