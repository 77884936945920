import {useMemo} from 'react';
import {scaleLinear} from 'd3-scale';
import {getColorScale} from '../../../../../../modules/ColorScale';
import {Amount} from '../../../../common/Amount/Amount';
import styles from './DepositChart.module.sass';

const createBarsData = (totalMin, totalMax) => {
  const colorScale = getColorScale([totalMin, totalMax]);
  const POSITIVE_COLUMN_HEIGHT = 40;
  const NEGATIVE_COLUMN_HEIGHT = 14;
  const COLUMN_WIDTH = 90;
  const CHART_WIDTH = 360;

  const colors = [
    '#E2EDFE',
    '#CBDFFD',
    '#B5D1FB',
    '#7BADF7',
  ]


  const scale = scaleLinear()
    .domain([totalMin, totalMax])
    .range([0, 360]);

  // const zeroPosition = scale(
  //   totalMin > 0
  //     ? totalMin
  //     : 0
  // );
  // const negativeWidth = zeroPosition;
  const positiveWidth = CHART_WIDTH;// - zeroPosition;

  // const negativeColumnsCount = Math.round(negativeWidth/COLUMN_WIDTH) || 1;
  const positiveColumnsCount = Math.round(positiveWidth / COLUMN_WIDTH) || 1;
  const positiveColumnsWidth = positiveWidth / positiveColumnsCount;
  // const negativeColumnsWidth = negativeWidth/negativeColumnsCount;
  const positiveColumnsHeight = POSITIVE_COLUMN_HEIGHT / positiveColumnsCount;
  // const negativeColumnsHeight = NEGATIVE_COLUMN_HEIGHT/negativeColumnsCount;

  let positiveColumns = [];
  // let negativeColumns = [];

  // if (totalMax > 0) {
  for (let i = 1; i <= positiveColumnsCount; i++) {
    const left = ((i - 1) * positiveColumnsWidth);

    positiveColumns.push({
      bottom: '0px',
      left,
      width: positiveColumnsWidth - 3,
      height: positiveColumnsHeight * i,
      background: colors[i-1]
    })
  }
  // }

  // if (totalMin < 0) {
  //   for (let i = 1; i <= negativeColumnsCount; i++) {
  //     const left = (i - 1) * negativeColumnsWidth;
  //
  //     negativeColumns.push({
  //       top: '35px',
  //       left,
  //       width: negativeColumnsWidth,
  //       height: NEGATIVE_COLUMN_HEIGHT - (negativeColumnsHeight * (i - 1)),
  //       background: colorScale(scale.invert(left))
  //     })
  //   }
  // }

  return [
    // ...negativeColumns,
    ...positiveColumns
  ]
};

export const DepositChart = ({totalMin, totalMax, usePlus}) => {
  const barsData = useMemo(() => createBarsData(totalMin, totalMax), [totalMin, totalMax])

  return (
    <div>
      <div className={styles.description}>
        <Amount value={totalMin}/>
        <Amount value={totalMax}/>
      </div>
      <div className={styles.columns}>
        {barsData.map((bar) => (
          <div
            key={bar.height} className={styles.column}
            style={{
              width: bar.width,
              height: bar.height,
              left: bar.left,
              top: bar.top,
              bottom: bar.bottom,
              background: bar.background,
            }}
          />
        ))}
      </div>
    </div>
  );
};
