import React from 'react';
import {Tooltip} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {formatAmount} from '../../../../../modules/format';
import {PERIODS, PERIODS_RU} from '../../../../../modules/Periods';
import {RATINGS, getRatingStatus} from '../../../../../modules/Ratings';
import {Amount} from '../../../common/Amount/Amount';
import {Filter} from '../Filter';
import {DepositFilter} from '../DepositFilter';
import {DepositChart, PeriodChart, RatingChart} from '../Filter/Charts';
import {PeriodStatus} from '../Filter/PeriodStatus';
import {MEASURED_VALUES_MAP} from '../HomeController';
import styles from './Filters.module.sass';
import homeStyles from '../styles.module.sass';

export const Filters = ({
                          measuredValue,
                          minMeasuredValue,
                          maxMeasuredValue,
                          measuredValueFilter,
                          setMeasuredValueFilter,
                          ratingFilter,
                          setRatingFilter,
                          periodFilter,
                          setPeriodFilter,
                        }) => {

  function processValue(newValueElement) {
    const minValue = minMeasuredValue;
    const maxValue = maxMeasuredValue;
    // if (minValue < 0) {
    //   console.log("newValueElement=", newValueElement);
    if (newValueElement < 25) {
      let percent = newValueElement / 25;
      newValueElement = minValue - minValue * (percent)
    } else if (newValueElement < 50) {
      let percent = (newValueElement - 25) / 25;
      newValueElement = (maxValue * 0.55) * percent;
    } else if (newValueElement < 75) {
      let percent = (newValueElement - 50) / 25;
      newValueElement = maxValue * 0.55 + (maxValue * 0.90 - maxValue * 0.55) * percent;
    } else {
      let percent = (newValueElement - 75) / 25;
      newValueElement = maxValue * 0.90 + (maxValue - maxValue * 0.90) * percent;
    }
    return newValueElement
    // }

    // return newValueElement;
  }

  return (
    <div className={styles.filters}>
      <Filter
        title={`Срок инвестиций: ${PERIODS_RU[periodFilter[0]]} — ${PERIODS_RU[periodFilter[1] - 1]}`}
        sliderValue={<PeriodStatus periods={PERIODS.slice(...periodFilter)}/>}
        chart={(<PeriodChart/>)}
        value={periodFilter}
        onChange={setPeriodFilter}
        minValue={0}
        maxValue={6}
      />

      <DepositFilter
        title={(<>
          Разница с депозитом: от&nbsp;<Amount value={processValue(measuredValueFilter[0])}/>
          <div className={homeStyles.helpIcon}>
            <Tooltip title={
              <div className={homeStyles.tooltipContent}>
                <div className={homeStyles.tooltipText}>
                  Выгода больше при длительных сроках и меньшей надежности.
                </div>
              </div>}>
              <HelpIcon/>
            </Tooltip>
          </div>
        </>)}
        disabled={!isFinite(minMeasuredValue) || !isFinite(maxMeasuredValue)}
        chart={(<DepositChart
          totalMin={minMeasuredValue}
          totalMax={maxMeasuredValue}
          usePlus={measuredValue === MEASURED_VALUES_MAP.DIFFERENCE_WITH_DEPOSIT}
        />)}
        onChange={setMeasuredValueFilter}
        value={measuredValueFilter}
        minValue={minMeasuredValue}
        maxValue={maxMeasuredValue}
        usePlus={measuredValue === MEASURED_VALUES_MAP.DIFFERENCE_WITH_DEPOSIT}
      />

      <Filter
        title={(<>
          Рейтинг облигаций: {RATINGS[ratingFilter[0]]} — {RATINGS[ratingFilter[1] - 1]}
          <div className={homeStyles.helpIcon}>
            <Tooltip title={<div className={homeStyles.tooltipContent}>
              <div className={homeStyles.tooltipBlock}>
                <div className={homeStyles.tooltipTitle}>
                  Низкий риск
                </div>
                <div className={homeStyles.tooltipText}>
                  Облигации покупают пенсионные фонды для хранения и приумножения пенсионных отчислений
                </div>
              </div>
              <div className={homeStyles.tooltipTitle}>
                Высокий риск
              </div>
              <div className={homeStyles.tooltipText}>
                Облигации с недостаточно высоким уровнем надежности. Только для опытных инвесторов
              </div>
            </div>}>
              <HelpIcon/>
            </Tooltip>
          </div>
        </>)}
        sliderValue={getRatingStatus(RATINGS.slice(...ratingFilter))}
        chart={(<RatingChart/>)}
        value={ratingFilter}
        onChange={setRatingFilter}
        minValue={0}
        maxValue={8}
      />
    </div>
  );
};
